/* eslint-disable */
export default {
 

 
  name: "Eray",

  //Contact Email
  contactEmail: "eraykayur@gmail.com",

  // Landing Page Name
  landingPageName: "Eray Kayur",

  // Landing Page Paragraph
  landingPagePara:
    "An Abitur student. Highly interested in Data Science",

  // Landing Page Image (replace - root/public/assets/photo.jpg)
  landingPageImage: "assets/1.jpg",


  projects: [
    {
      id: 6, 
      title: "Forza Racing",
      short: "Racing Game, set the fastest lap",
      tech: "C#, Unity, OOP",
      imageSrc: "assets/Forza-Racing.png",
      git: "https://github.com/Tecmac/Forza-Racing",
      url: "https://github.com/Tecmac/Forza-Racing"
    },
    {
      id: 1, 
      title: "Race Prediction Game", //Project Title - Add Your Project Title Here
      short: "Gamble the results of an upcoming F1 race",
      tech: "Python, Psycopg, Postgres, FastAPI Password Hashing, Threading, OOP ",
      git: "https://github.com/Tecmac/RacePredictionGame",
      // Image Size Should be (300X300 px)
      imageSrc: "assets/RacePredictionGame.png",
      url: "https://github.com/Tecmac/RacePredictionGame"
    },
    {
      id: 2, 
      title: "Landmarks ",
      short: "A IOS app to see infos about some popular landmarks in the U.S.A.",
      tech: "Swift",
      git: "https://github.com/Tecmac/Landmarks",
      imageSrc: "assets/Landmarks.png",
      url: "https://github.com/Tecmac/Landmarks"
    },
    {
      id: 3, 
      title: "ESP32 Homekit",
      short: "Controll lights via Apple Homekit",
      git: "https://github.com/Tecmac/ESP32-Homekit",
      tech: "C, Homespan",
      imageSrc: "assets/ESP32-Homekit.jpeg",
      url: "https://github.com/Tecmac/ESP32-Homekit"
  
    },
    {
      id: 5,
      title: "Github",
      short: "Check out my other Projects",
      tech: "",
      imageSrc: "assets/GitHub_Logo.png",
      git: "https://github.com/Tecmac",
      url: "https://github.com/Tecmac"
    },
    
  

    /*
                    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
                ,{
                id: 7,
                service: 'Something Amazing',
                imageSrc: "",
                url: ''
            }
                */
  ],

  // Contact Section
  contactPara:
    "Simply shoot me an email or message me on LinkedIn! :)",
  social: [
    // Add Or Remove The Link Accordingly
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/eray-kayur/"
    },
    {
      name: "Github",
      url: "https://github.com/schumilton"
    },
  ],

  // About Page
  aboutTitle: "I make remarkable digital products",
  aboutPara:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry 's standard dummy text eversince the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
};


import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Resume from './components/Resume';
import Landing from './components/Landing';
import Test from './components/Test';

function App() {
  return (
    <BrowserRouter>
      <Routes>
       <Route path="/resume" element={<Resume />} />
      <Route path="/" element={<Landing />} exact />
      <Route path="/test" element={<Test />} />
     
      </Routes>
    </BrowserRouter>
  );
}

export default App;
